<template>
    <v-row>
        <v-col cols="12">
            <h2 class="main-heading">
                {{ $t(`Admin.sme.mainHeading`) }}
            </h2>
            <base-card class="fill-height">
                <template #content>
                    <v-card-text class="flex-col-between fill-height">
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            :loading="handleListingStatus_Pending"
                            :loading-text="$t(`Admin.dataTable.loadingText`)"
                            :no-data-text="$t(`Admin.dataTable.noDataText`)"
                            :no-results-text="$t(`Admin.dataTable.noResultsText`)"
                            :page.sync="page"
                            hide-default-footer
                            disable-sort
                        >
                            <template v-slot:top>
                                <v-row class="mb-4">
                                    <v-col
                                        cols="12"
                                        class="d-flex justify-end"
                                    >
                                        <v-btn
                                            depressed
                                            dense
                                            outlined
                                            link
                                            :to="{name: 'CreateEditSme'}"
                                            color="primary"
                                        >
                                            <v-icon
                                                size="14"
                                                class="mr-2"
                                            >
                                                fa-solid fa-plus
                                            </v-icon>
                                            {{ $t(`Admin.sme.createNew`) }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <dropdown-menu
                                    :is-text="true"
                                    :can-close="true"
                                    icon="fas fa-ellipsis-v"
                                >
                                    <template #listItems>
                                        <v-list>
                                            <v-list-item class="item-hover px-0">
                                                <v-btn
                                                    text
                                                    color="accent darken-4"
                                                    @click="editItem(item)"
                                                >
                                                    <v-icon
                                                        size="12"
                                                        class="mr-2"
                                                    >
                                                            fa-solid fa-pen
                                                    </v-icon>
                                                    <span class="table-menu-item">{{ $t(`Admin.dataTable.tableActions.edit`) }}</span>
                                                </v-btn>
                                            </v-list-item>
                                            <v-list-item class="item-hover px-0">
                                                <v-btn
                                                    text
                                                    color="error lighten-1"
                                                    @click="deleteItem(item)"
                                                >
                                                    <v-icon
                                                        size="12"
                                                        class="mr-2"
                                                    >
                                                            fa-solid fa-trash
                                                    </v-icon>
                                                    <span class="table-menu-item">{{ $t(`Admin.dataTable.tableActions.delete`) }}</span> 
                                                </v-btn>
                                            </v-list-item>
                                        </v-list>
                                    </template>
                                </dropdown-menu>
                            </template>
                        </v-data-table>

                        <div v-if="pageCount > 1">
                            <v-divider class="mb-4"></v-divider>

                            <div class="text-center table-pagination">
                                <v-pagination
                                    v-model="page"
                                    class="table-pagination"
                                    :length="pageCount"
                                    :total-visible="7"
                                    elevation="0"
                                ></v-pagination>
                            </div>
                        </div>
                    </v-card-text>
                </template>
            </base-card>
            <confirm-modal
                :open="openModal"
                :close.sync="openModal"
            >
                <template #content>
                    <span class="modal-content">
                        {{ $t(`Admin.sme.confirmTxt`) }}
                    </span>
                </template>
                <template #action>
                    <v-btn
                        depressed
                        dense
                        color="error lighten1"
                        class="mr-2"
                        :loading="confirmStatus_Pending"
                        @click="confirm()"
                    >
                        {{ $t(`Admin.modal.confirm`) }}
                    </v-btn>
                </template>
            </confirm-modal>
        </v-col>
    </v-row>
</template>

<script>
import { apiStatus } from '@/api/constants/apiStatus'
import { apiStatusComputed } from "@/api/helpers/computedApiStatus"
import { withAsync } from "@/helpers/withAsync"
import { getAllSmes, deleteSme } from "@/api/adminApi.js"
import BaseCard from "@/components/base/BaseCard.vue";
import DropdownMenu from "./components/DropdownMenu.vue"
export default {
    name: "SmeView",

    components: {
        BaseCard,
        DropdownMenu,
        ConfirmModal: () => import("@/components/ConfirmModal.vue"),
    },

    data() {
        return {
            items: [],
            page: 1,
            pageCount: 0,
            openModal: false,
            itemToDelete: {},
            handleListingStatus: apiStatus.Idle,
            confirmStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["handleListingStatus", "confirmStatus"]),
        headers() {
            return [
                { text: 'Id', value: 'id'},
                { text: this.$t(`Admin.dataTable.headers.title`), value: 'title' },
                { text: this.$t(`Admin.dataTable.headers.description`), value: 'description' },
                { text: this.$t(`Admin.dataTable.headers.user`), value: 'user.name' },
                { text: this.$t(`Admin.dataTable.headers.actions`), value: 'actions', sortable: false, },
            ]
        },
    },

    methods: {
        async fetchAllSmeListings() {
            this.handleListingStatus = apiStatus.Pending;

            const payload = {
                page: this.page,
                items: 6,
            }
            
            const { response, error } = await withAsync(getAllSmes, payload);

            if (error) {
                this.handleListingStatus = apiStatus.Error;
                return;
            }
            this.items = response.data.data;
            this.pageCount = response.data.last_page;
            this.handleListingStatus = apiStatus.Success;
        },

        async confirm() {
            this.confirmStatus = apiStatus.Pending;
            const payload = {
                id: this.itemToDelete.id,
            };

            const { response, error } = await withAsync(deleteSme, payload)

            if (error) {
                this.confirmStatus = apiStatus.Error;
                return;
            }
            this.confirmStatus = apiStatus.Success;
            this.openModal = false;
            this.fetchAllSmeListings();

        },

        editItem(item) {
            this.$router.push({name: "CreateEditSme", params: {id: item.id}})
        },

        deleteItem(item) {
            this.openModal = true;
            this.itemToDelete = item;
        }
    },
    watch: {
        page(newVal){
            this.fetchAllSmeListings();
        }
    },

    created() {
        this.fetchAllSmeListings();
    }
}
</script>

<style lang="scss" scoped>
.table-menu-item {
    font-size: 1.1em;
    font-weight: 500;
    letter-spacing: .06em;  
}
</style>